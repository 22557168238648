// cropType api
export const cropList = '/seeds/config/crop-types/list'
export const cropTypeStore = '/seeds/config/crop-types/store'
export const cropTypeUpdate = '/seeds/config/crop-types/update'
export const cropTypeToggleStatus = '/seeds/config/crop-types/toggle-status'
export const cropTypeDestroy = '/seeds/config/crop-types/destroy'

// cropName api
export const cropNameList = '/seeds/config/crop-name/list'
export const cropNameStore = '/seeds/config/crop-name/store'
export const cropNameUpdate = '/seeds/config/crop-name/update'
export const cropNameToggleStatus = '/seeds/config/crop-name/toggle-status'
export const cropNameDestroy = '/seeds/config/crop-name/destroy'

// collection report
export const collectionReport = '/germplasm/report/collection-report'

// Germplasm Production Report
export const productionReportInfo = '/germplasm/report/production-report'
export const reportHeadingList = '/germplasm/config/report-heading/detail'

// Germplasm Distribution Report
export const distributionReport = '/germplasm/report/distribution-report'

// germplasm crop excel
export const germplasmCropExcel = '/germplasm/germplasm/conservation-details/crop-info'
export const checkStatusReport = '/germplasm/report/check-status-report'
