<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('germplasmReport.germplasm-distribution') }}</h4>
        </template>
       <template v-slot:body>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
             <b-row>
                <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('germplasmReport.distribution_date_from')"
                    label-for="from_date"
                  >
                    <b-form-input
                      class="fromDate"
                        v-model="search.from_date"
                      :placeholder="$t('germplasmReport.distribution_date_from')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col xl="6" lg="6" md="6" sm="12" xs="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('germplasmReport.distribution_date_to')"
                    label-for="to_date"
                  >
                    <b-form-input
                      class="toDate"
                        v-model="search.to_date"
                      :placeholder="$t('germplasmReport.distribution_date_to')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Organization" vid='org_id'>
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('globalTrans.organization')"
                        label-for="org_id"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.organization')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="organizationtList"
                          id="org_id"
                          :disabled="$store.state.dataFilters.orgId > 0"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Request_Type" vid='request_type'>
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="request_type"
                        :label="$t('germplasmReport.dealer')"
                    >
                      <template v-slot:label>
                        {{ $t('germplasmReport.dealer')}}
                      </template>
                      <b-form-select
                          plain
                          v-model="search.request_type"
                          :options="options"
                          id="request_type"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- <b-col lg="6" sm="12">
                  <ValidationProvider name="Accession Number" vid="accession_number">
                    <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="accession_number"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('germplasmReport.accession_number') }}
                      </template>
                      <b-input
                          plain
                          v-model="search.accession_number"
                          id="accession_number"
                          :state="errors[0] ? false : (valid ? true : null)"
                      >
                      </b-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col> -->
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Crop Type" vid="crop_type_id">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="crop_type_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('germplasmReport.crop_type') }}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.crop_type_id"
                        :options="GpCropTypeList"
                        id="crop_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                  <b-col xs="12" sm="12" md="12">
                    <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
      </template>
    </iq-card>
        <b-row v-show="showData">
        <b-col md="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay :show="loadingState">
                  <b-row v-show="showData">
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{  $t('germplasmReport.germplasm-distribution') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                          </b-button>
                          <!--  <export-excel
                            class="btn btn_add_new ml-2"
                            :data="dataCustomizeExcel"
                            :fields="json_fields"
                            :title="$t('germplasmReport.germplasm-production-report')"
                            worksheet="Germplasn Production Report"
                            name="filename.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                          </export-excel> -->
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id= "search.org_id > 0 ? search.org_id : 1">
                                  {{ $t('germplasmReport.germplasm-distribution')}}
                                </list-report-head>
                              </b-col>
                              </b-row>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                 <b-table bordered hover :items="productionReport" :fields="columns" thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                  <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                  </template>
                                   <template v-slot:cell(accession_number)="data">
                                    {{ $n(data.item.accession_number, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(distribute_quantity)="data">
                                    {{ $n(data.item.distribute_quantity, { useGrouping: false }) }}
                                  </template>
                                  <template v-slot:cell(status)="data">
                                    {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                                  </template>
                                </b-table>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, distributionReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
import flatpickr from 'flatpickr'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'accession Number': 'accessionNumber',
  'crop Type': 'cropType',
  'Crop Name': 'CropName',
  'scientific Name': 'scientificName',
  'english Name': 'englishName',
  'local Name': 'localName',
  quantity: 'quantity'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'যোজন সংখ্যা': 'accessionNumber',
  'ফসলের ধরণ': 'cropType',
  'ফসলে নাম': 'CropName',
 'বৈজ্ঞানিক নাম': 'scientificName',
  'ইংরেজি নাম': 'englishName',
  'স্থানীয় নাম': 'localName',
  পরিমাণ: 'quantity'
}

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      showData: false,
      showHeading: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        from_date: '',
        to_date: '',
        accession_number: '',
        crop_type_id: 0,
        org_id: this.$store.state.dataFilters.orgId,
        request_type: 0
      },
      productionReport: [],
      excelData: [],
      testId: 0,
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      loading: false,
      options: [
        { value: 1, text: this.$t('germplasm.germplasm_organization') },
        { value: 2, text: this.$t('germplasm.germplasm_receiver') },
        { value: 3, text: this.$t('germplasm.farmer') }
      ]
    }
  },
  computed: {
      organizationtList: function () {
        return this.$store.state.commonObj.organizationProfileList
      },
       GpCropTypeList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.gpCropTypeList.filter(item => item.status === 1)
      },
      json_fields: function () {
        return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
      },
      dataCustomizeExcel () {
        var serial = 0
        const customizeData = this.productionReport.map(item => {
          serial += 1
          if (this.currentLocale === 'bn') {
            item.serial = this.$n(serial)
            item.accessionNumber = this.$n(item.accession_number)
            item.cropType = item.crop_type_bn
            item.cropName = item.crop_name_bn
            item.scientificName = item.scientific_name_bn
            item.englishName = item.english_name_bn
            item.localName = item.local_name_bn
            item.quantity = this.$n(item.quantity)
          } else {
            item.serial = this.$n(serial)
            item.accessionNumber = this.$n(item.accession_number)
            item.cropType = item.crop_type
            item.cropName = item.crop_name
            item.scientificName = item.scientific_name
            item.englishName = item.english_name
            item.localName = item.local_name
            item.quantity = this.$n(item.quantity)
          }
          return Object.assign({}, item)
        })
        return customizeData
      },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.organization'), class: 'text-left' },
          // { label: this.$t('germplasmReport.dealer'), class: 'text-left' },
        //   { label: this.$t('germplasmReport.accession_number'), class: 'text-left' },
          { label: this.$t('germplasmReport.crop_type'), class: 'text-left' },
          { label: this.$t('germConfig.cropName'), class: 'text-left' },
          { label: this.$t('germConfig.scientific_name'), class: 'text-left' },
          { label: this.$t('germConfig.english_name'), class: 'text-left' },
        //   { label: this.$t('germplasmReport.local_name'), class: 'text-left' },
          { label: this.$t('germplasmReport.distribution_quantity'), class: 'text-left' },
          { label: this.$t('germplasm.unit'), class: 'text-left' }
        ]
      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
        //   { key: 'accession_number' },
          { key: 'crop_type_bn' },
          { key: 'crop_name_bn' },
          { key: 'scientific_name_bn' },
          { key: 'english_name_bn' },
        //   { key: 'local_name_bn' },
          { key: 'distribute_quantity' },
          { key: 'unit_name_bn' }
        ]
      } else {
        keys = [
           { key: 'index' },
          { key: 'org_name' },
        //   { key: 'accession_number' },
          { key: 'crop_type' },
          { key: 'crop_name' },
          { key: 'scientific_name' },
          { key: 'english_name' },
        //   { key: 'local_name' },
          { key: 'distribute_quantity' },
          { key: 'unit_name' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {},
  created () {
    // this.loadData()
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
    flatpickr('.toDate', {})
  },
  methods: {
    searchClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    searchData () {
      this.organizationData()
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    organizationData () {
      const orgId = this.search.org_id > 0 ? this.search.org_id : 1
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === orgId)

      RestApi.getData(seedFertilizerServiceBaseUrl, reportHeadingList + '/' + orgId, { org_id: 3 }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Organization not found!'
          })
        }
      })
    },
   loadData () {
      this.loading = true
      RestApi.getData(seedFertilizerServiceBaseUrl, distributionReport, this.search).then(response => {
        if (response.success) {
          this.productionReport = this.formatData(response.data)
        }
        this.loading = false
      }).catch(error => {
        if (error) {
          //
        }
        this.productionReport = []
      })
    },
    formatData (data) {
      const formattedData = data.map(item => {
        const cropTypeList = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.find(cropType => cropType.value === item.crop_type_id && cropType.status === 1)
        const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.find(cropName => cropName.value === item.crop_name_id && cropName.status === 1)
        const orgNameList = this.organizationtList.find(orgItem => orgItem.value === item.org_id && orgItem.status === 0)
        const unit = this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.find(obj => obj.value === parseInt(item.unit_id))
        const tmpBindingData = {
          crop_type: cropTypeList !== undefined ? cropTypeList.text_en : '',
          crop_type_bn: cropTypeList !== undefined ? cropTypeList.text_bn : '',
          crop_name: cropNameList !== undefined ? cropNameList.text_en : '',
          crop_name_bn: cropNameList !== undefined ? cropNameList.text_bn : '',
          english_name: cropNameList !== undefined ? cropNameList.english_name : '',
          english_name_bn: cropNameList !== undefined ? cropNameList.english_name_bn : '',
          scientific_name: cropNameList !== undefined ? cropNameList.scientific_name : '',
          scientific_name_bn: cropNameList !== undefined ? cropNameList.scientific_name_bn : '',
          org_name: orgNameList !== undefined ? orgNameList.text_en : '',
          org_name_bn: orgNameList !== undefined ? orgNameList.text_bn : '',
          unit_name: unit?.text_en,
          unit_name_bn: unit?.text_bn
        }
        return Object.assign({}, item, tmpBindingData)
      })
      return formattedData
    },
    pdfExport () {
      const rowData = this.getPdfData()

      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('globalTrans.organization'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        //   { text: this.$t('germplasmReport.accession_number'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germplasmReport.crop_type'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germConfig.cropName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germConfig.scientific_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germConfig.english_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        //   { text: this.$t('germplasmReport.local_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('germplasmReport.distribution_quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
        { text: this.$t('germplasm.unit'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Germplasm Distribution Report' : 'জার্মপ্লাজম বিতরণ প্রতিবেদন'
      const columnWids = ['5%', '15%', '10%', '10%', '15%', '15%', '10%', 'auto']
      const orgId = this.search.org_id > 0 ? this.search.org_id : 1
      ExportPdf.exportPdf(seedFertilizerServiceBaseUrl, reportHeadingList, orgId, reportTitle, rowData, columnWids)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'org_name' : 'org_name_bn' },
        //   { key: this.$i18n.locale === 'en' ? 'accession_number' : 'accession_number' },
          { key: this.$i18n.locale === 'en' ? 'crop_type' : 'crop_type_bn' },
          { key: this.$i18n.locale === 'en' ? 'crop_name' : 'crop_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'scientific_name' : 'scientific_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'english_name' : 'english_name_bn' },
        //   { key: this.$i18n.locale === 'en' ? 'local_name' : 'local_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'distribute_quantity' : 'distribute_quantity' },
          { key: this.$i18n.locale === 'en' ? 'unit_name' : 'unit_name_bn' }
        ]
      var serial = 0
      const listData = this.productionReport.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'distribute_quantity') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'quantity') {
                return { text: this.$n(item[keyItem.key]) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
